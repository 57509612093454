import React, { Component } from 'react';
import SVG from 'react-inlinesvg';
import { graphql, Link } from 'gatsby';
import { List, Avatar, Select, Drawer, Carousel } from 'antd';

import Layout from '../components/layout';
import styles from './angebote.module.css';
import { groups, staircases, mapping } from '../config';
import { forEach } from '../utils';

export default class extends Component {
  state = {
    type: 'all',
    drawerVisible: null,
  };

  onClick = (id) => {
    this.setState({ drawerVisible: id });
    this.onScroll();
  };

  onMouseOver = (el) => {
    const id = el.target ? el.target.getAttribute('data-locationid') : el;
    if (!id) return;
    if (this.state.type === 'all' && !groups.includes(id)) this.showSecondFloor();
    if (staircases.includes(id)) {
      forEach(document.querySelectorAll(`#${id} path[id^="BG"]`), (el) => el.classList.add(styles.strong));
      forEach(document.querySelectorAll(`#${id} [id^="BG"] path`), (el) => el.classList.add(styles.strong));
    } else {
      forEach(document.querySelectorAll(`#${id} text`), (t) => t.classList.add(styles.hoveredText));
      forEach(document.querySelectorAll(`#${id} path`), (p) => p.classList.add(styles.hoveredPath));
    }
  };

  onMouseLeave = (el) => {
    const id = el.target ? el.target.getAttribute('data-locationid') : el;
    if (!id) return;
    if (this.state.type === 'all' && !groups.includes(id)) this.showFirstFloor();
    if (staircases.includes(id)) {
      forEach(document.querySelectorAll(`#${id} path[id^="BG"]`), (el) => el.classList.remove(styles.strong));
      forEach(document.querySelectorAll(`#${id} [id^="BG"] path`), (el) => el.classList.remove(styles.strong));
    } else {
      forEach(document.querySelectorAll(`#${id} path`), (p) => p.classList.remove(styles.hoveredPath));
      forEach(document.querySelectorAll(`#${id} text`), (t) => t.classList.remove(styles.hoveredText));
    }
  };

  onScroll = () => {
    forEach(document.querySelectorAll(`svg path`), (el) => el.classList.remove(styles.hoveredPath));
    forEach(document.querySelectorAll(`svg text`), (el) => el.classList.remove(styles.hoveredText));
  };

  onLoad = (ids) => {
    ids.forEach((id) => {
      forEach(document.querySelectorAll(`#${id} *`), (el) => {
        el.addEventListener('click', () => this.onMapClick(id));
      });
    });
  };

  onMapClick = (id) => {
    const item = this.mapped.find(({ locationId }) => locationId === id);
    this.setState({ drawerVisible: item ? item.id : true });
  };

  showSecondFloor = () => this.toggleFloor(true);
  showFirstFloor = () => this.toggleFloor(false);

  toggleFloor = (show) => {
    const type = show ? 'add' : 'remove';
    groups.forEach((group) => {
      forEach(document.querySelectorAll(`#${group} path`), (el) => el.classList[type](styles.dimmed));
      forEach(document.querySelectorAll(`#${group} text`), (p) => p.classList[type](styles.invisible));
    });
  };

  onChangeType = (type) => {
    if (type !== 'all') {
      const [group, categ] = type.split('__');
      if (group === 'Office') this.showSecondFloor();
      if (group === 'Shop') this.showFirstFloor();
      this.mapped.forEach(({ category, locationId }) => {
        if (categ === category) {
          this.onMouseOver(locationId);
        } else {
          this.onMouseLeave(locationId);
        }
      });
    }
    this.setState({ type });
  };

  onClose = () => {
    this.setState({ drawerVisible: null });
  };

  renderDrawerContent = (item) => {
    if (!item)
      return (
        <div>
          <div className={styles.drawerTitle}>
            <h1>Ihre Chance</h1>
          </div>
          <div className={styles.drawerContent}>
            <div>
              <p>Hier könnte ihr Ladengeschäft oder Büro entstehen.</p>
              <p>
                Nehmen Sie Kontakt zum <Link to="/service-infos">Centermanagement</Link> auf.
              </p>
            </div>
          </div>
        </div>
      );
    return (
      <div>
        <div className={styles.drawerTitle}>
          <Avatar shape="square" size="large" className={styles.avatar} src={item.icon ? item.icon.file.url : null} />
          <h1>{item.header}</h1>
        </div>
        <div className={styles.drawerContent}>
          <div>
            {item.address ? (
              <>
                <h3>Kontakt</h3>
                <div dangerouslySetInnerHTML={{ __html: item.address.childMarkdownRemark.html }} />
              </>
            ) : null}
            {item.openingHours && item.openingHours.length ? (
              <>
                <h3>Öffnungzeiten</h3>
                <List size="small">
                  {item.openingHours.map((openingHour) => {
                    const [days, hours] = openingHour.split('/');
                    return (
                      <List.Item key={openingHour}>
                        <List.Item.Meta title={days} />
                        <div style={{ marginLeft: '10px' }}>{hours}</div>
                      </List.Item>
                    );
                  })}
                </List>
              </>
            ) : null}
          </div>
          <div>
            {item.images && item.images.length ? (
              <Carousel centerMode swipe autoplay variableWidth>
                {item.images.map(({ file: { url }, description }) => (
                  <div key={url}>
                    <img className={styles.image} src={url} style={{ height: '250px' }} alt={description} />
                  </div>
                ))}
              </Carousel>
            ) : null}
            {item.content ? <div dangerouslySetInnerHTML={{ __html: item.content.childMarkdownRemark.html }} /> : null}
          </div>
        </div>
      </div>
    );
  };

  render() {
    const {
      places: { edges },
    } = this.props.data;
    const { type, drawerVisible } = this.state;
    const [group, category] = type.split('__');
    const secondFloor = group === 'offices';
    const jpg = secondFloor ? '/map_t2_fb.jpg' : '/map_t1_fb.jpg';
    this.mapped = edges.map(({ node }) => {
      if (node.group === 'Office') return { ...node, locationId: node.location.startsWith('T1') ? 'T1' : 'T2' };
      if (+node.location) return { ...node, locationId: `G${node.location}` };
      return node;
    }, []);
    const filtered = this.mapped.filter((node) => {
      if (type !== 'all') {
        if (group !== node.group || category !== node.category) return false;
      }
      return true;
    });
    const selects = this.mapped.reduce((red, { category, group, locationId }) => {
      const values = red[group] || [];
      if (values.find(({ category: c }) => category === c)) return red;
      return { ...red, [group]: [...values, { category, locationId }] };
    }, {});
    const currentItem = filtered.find(({ id }) => id === drawerVisible);
    return (
      <Layout>
        <div className={styles.root}>
          <h1>Angebote</h1>
          <div className={styles.wrapper}>
            <SVG className={styles.svg} src="/map.svg" uniquifyIDs={false} onLoad={() => this.onLoad(groups)}>
              <img src={jpg} alt="Angebotskarte" />
            </SVG>
          </div>
          <div className={styles.body}>
            <Select className={styles.select} value={type} onChange={this.onChangeType}>
              <Select.Option value="all">ALLE ANGEBOTE</Select.Option>
              {Object.entries(selects).map(([key, values]) => {
                return (
                  <Select.OptGroup key={key} label={mapping[key]}>
                    {values.map(({ category, locationId }) => {
                      const value = `${key}__${category}__${locationId}`;
                      return (
                        <Select.Option key={value} value={value}>
                          {category}
                        </Select.Option>
                      );
                    })}
                  </Select.OptGroup>
                );
              })}
            </Select>
            <Drawer placement="right" onClose={this.onClose} visible={!!drawerVisible}>
              {this.renderDrawerContent(currentItem)}
            </Drawer>
            <List
              className={styles.list}
              dataSource={filtered}
              onScroll={this.onScroll}
              renderItem={({ icon, header, location, locationId, id }) => {
                return (
                  <List.Item
                    key={id}
                    className={styles.listItem}
                    data-locationid={locationId}
                    onMouseOver={this.onMouseOver}
                    onMouseLeave={this.onMouseLeave}
                    onClick={() => this.onClick(id)}
                  >
                    <List.Item.Meta avatar={<Avatar className={styles.avatar} src={icon ? icon.file.url : null} />} title={header} />
                    <div>{location}</div>
                  </List.Item>
                );
              }}
            />
          </div>
        </div>
      </Layout>
    );
  }
}

export const query = graphql`
  query AngebotePageQuery {
    places: allContentfulAngebote {
      edges {
        node {
          id
          header
          location
          category
          group
          openingHours
          phone
          mail
          website
          images {
            description
            file {
              url
            }
          }
          address {
            childMarkdownRemark {
              html
            }
          }
          content {
            childMarkdownRemark {
              html
            }
          }
          icon {
            file {
              url
            }
          }
        }
      }
    }
  }
`;
